import React from 'react'
import Layout from '../components/layout.js'
import Seo from '../components/seo.js'
import { Grid } from 'semantic-ui-react'
import './government.css.less'
import bgImg from '../images/govibgblue.png'
import headerImg from '../images/govbg.png'
import msGlass from '../images/mag-str.png'
import mslantGlass from '../images/mag-slant.png'
import govIcon1 from '../images/computer-icon.png'
import govIcon2 from '../images/cloud.png'
import govIcon3 from '../images/phone-icon.png'
import govIcon4 from '../images/multi-icon.png'
import govIcon5 from '../images/teacher-icon.png'
import govIcon6 from '../images/camera-icon.png'
import Lea from '../images/lea_blue.png'

function Government() {
	return (
		<Layout>
			<Seo title="government" />
			<div className="government-container">
				<header className="v-header gov-container">
					<div className="gov-image-wrap">
						<img
							src={bgImg}
							alt=""
							className="gov-image"
							style={{ backgroundSize: 'contain' }}
						/>
						{/* <img src={msGlass} alt="" className="gov-msGlass-r" /> */}
						<img src={msGlass} alt="" className="gov-msGlass-l" />
					</div>
					<div
						className="gov-header-content"
						style={{
							backgroundImage: `url(${headerImg})`,
							backgroundSize: 'cover'
						}}
					>
						<div className="gov-header-title">
							<h5>GOVERNMENT</h5>
						</div>
					</div>
				</header>
				<div className="section-a">
					<div className="ui container ">
						<Grid stackable>
							<Grid.Row>
								<Grid.Column width={3}>
									<img src={govIcon1} alt="" className="gov-icon" />
								</Grid.Column>
								<Grid.Column width={5}>
									<span>
										Accessible and efficient legal assistance <br /> through
										technology
									</span>
								</Grid.Column>
								<Grid.Column width={3}>
									<img src={govIcon4} alt="" className="gov-icon" />
								</Grid.Column>
								<Grid.Column width={5}>
									<span className="text-highlight">
										Organized <br /> and digitally <br />
										available ordinances <br />
										for easy reference
									</span>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={3}>
									<img src={govIcon2} alt="" className="gov-icon" />
								</Grid.Column>
								<Grid.Column width={5}>
									<span className="text-highlight">
										Cloud-based legal services
										<br /> using internet-enabled <br />
										computers and devices
									</span>
								</Grid.Column>
								<Grid.Column width={3}>
									<img src={govIcon5} alt="1" className="gov-icon" />
								</Grid.Column>
								<Grid.Column width={5}>
									<span>
										MyLegalWhiz{' '}
										<img src={Lea} alt="" style={{ height: '1em' }} />
										™,
										<br /> the concierge legal <br /> research assistance <br />
										program for lawyers and <br /> government officials
									</span>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={3}>
									<img src={govIcon3} alt="" className="gov-icon" />
								</Grid.Column>
								<Grid.Column width={5}>
									<span>
										Mobile-optimized <br /> legal research tool <br />
										for government officials <br /> and judges
									</span>
								</Grid.Column>
								<Grid.Column width={3}>
									<img src={govIcon6} alt="" className="gov-icon" />
								</Grid.Column>
								<Grid.Column width={5}>
									<span className="text-highlight">
										Data analysis of legal <br />
										issues of the LGU
										<br /> that can be used as a <br />
										basis for policy
										<br /> making and <br />
										ordinance drafting
									</span>
								</Grid.Column>
							</Grid.Row>
							<img src={mslantGlass} alt="" className="gov-mslantGlass" />
						</Grid>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Government
